import userData from './components/user-data/index.vue'
import userContact from './components/user-contact/index.vue'
import userAddresses from './components/user-addresses/index.vue'
import userComment from './components/user-comment/index.vue'
import {mapGetters} from "vuex";

export default {
  name: "user-info",
  components: {
    userData,
    userContact,
    userAddresses,
    userComment
  },
  created() {

  },
  computed:{
    ...mapGetters({
      user: 'profile/user',
      isProfessional:'profile/isProfessional'
    })

  }
}
