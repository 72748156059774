import {mapActions, mapGetters} from "vuex";
import {maxLength, required, email, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "user-data",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        phone: '',
        email: ''
      },
      isEdit: false,
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      phone: {
        required,
        minLength: minLength(13)
      },
      email: {
        email,
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/user',
      updateUserLoading: 'profile/updateUserLoading'
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 13))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    }
  },
  watch: {
    'user'(newVal) {
      if (newVal) {
        if (this.user)
          this.setUserInfo()
      }
    }
  },
  created() {
    if (this.user)
      this.setUserInfo()
  },
  methods: {
    ...mapActions({
      updateUserData: 'profile/UPDATE_USER_DATA',
      fetchUser: 'profile/FETCH_USER_DATA'
    }),
    setUserInfo() {
      this.payload.phone = this.user.phone
      this.payload.email = this.user.email
    },
    resetForm() {
      this.isEdit = false
      this.$v.$reset()
      this.setUserInfo()
    },
    update() {

      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(1);
        this.updateUserData(this.payload).then(() => {
          this.$toasted.success(this.$t('successResetContact'));
          this.fetchUser()
          this.isEdit = false
        })
      }
    }
  }
}
