import {maxLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "user-addresses",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        recipient_name: '',
        recipient_second_name: '',
        recipient_phone: '',
        country: '',
        city: '',
        street: '',
        building: '',
        flat: '',
        is_main: 0,
      },
      isEdit: false,
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      recipient_name: {
        required,
      },
      recipient_second_name: {
        required,
      },
      recipient_phone: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      street: {
        required,
      },
      building: {
        required,
      },
      flat: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/user'
    }),
    recipientNameErrors() {
      let error = [];
      if (!this.$v.payload.recipient_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.recipient_name) {
        this.validationErrors.recipient_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    recipientSecondNameErrors() {
      let error = [];
      if (!this.$v.payload.recipient_second_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_second_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.recipient_second_name) {
        this.validationErrors.recipient_second_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    recipientPhoneErrors() {
      let error = [];
      if (!this.$v.payload.recipient_phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.recipient_phone) {
        this.validationErrors.recipient_phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    streetErrors() {
      let error = [];
      if (!this.$v.payload.street.$dirty) {
        return error;
      }
      if (!this.$v.payload.street.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.street) {
        this.validationErrors.street.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    buildindErrors() {
      let error = [];
      if (!this.$v.payload.building.$dirty) {
        return error;
      }
      if (!this.$v.payload.building.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.building) {
        this.validationErrors.building.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    flatErrors() {
      let error = [];
      if (!this.$v.payload.flat.$dirty) {
        return error;
      }
      if (!this.$v.payload.flat.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.flat) {
        this.validationErrors.flat.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createAddress: 'profile/CREATE_ADDRESS',
      makeMainAddress: 'profile/ADDRESS_MAIN',
      deleteAddress: 'profile/DELETE',
      fetchUser:'profile/FETCH_USER_DATA'
    }),
    reset() {
      this.isEdit = false
      this.$v.$reset()
    },
    resetInputs() {
      this.payload.recipient_name = ''
      this.payload.recipient_second_name = ''
      this.payload.recipient_phone = ''
      this.payload.city = ''
      this.payload.country = ''
      this.payload.building = ''
      this.payload.flat = ''
      this.payload.street = ''
      this.$v.$reset()
      this.isEdit = false
    },
    changeMainAddress(address){
      if(!address.isMain){
        this.makeMainAddress(address.id).then(()=>{
          this.fetchUser()
          this.$toasted.success(this.$t('successChangeAddress'));
        })
      }
    },
    addressDelete(address){
      this.deleteAddress(address.id).then(()=>{
        this.fetchUser()
        this.$toasted.success(this.$t('successDeleteAddress'));
      })
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createAddress(this.payload).then(() => {
          this.fetchUser()
          this.$toasted.success(this.$t('successAddNewAddress'));
          this.resetInputs()
        })
      }
    }
  },
}