import {mapActions, mapGetters} from "vuex";
import {maxLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import Vue from 'vue'
// Vue.use(Vuelidate)
export default {
  name: "user-data",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        secondName: '',
        name: '',
        lastName: '',
        country: '',
        city: '',
        work: ''
      },

      isEdit: false,
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(500),
      },
      secondName: {
        required,
        maxLength: maxLength(500),
      },
      lastName: {
        required,
        maxLength: maxLength(500),
      },
      country: {
        required,
        maxLength: maxLength(500),
      },
      city: {
        required,
        maxLength: maxLength(500),
      },

    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/user',
      isProfessional: 'profile/isProfessional',
      updateUserLoading: 'profile/updateUserLoading'
    }),
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.secondName.$dirty) {
        return error;
      }
      if (!this.$v.payload.secondName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.secondName.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.secondName) {
        this.validationErrors.secondName.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.lastName.$dirty) {
        return error;
      }
      if (!this.$v.payload.lastName.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.lastName.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.lastName) {
        this.validationErrors.lastName.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.country.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    addressErrors() {

      let error = [];
      if (!this.$v.payload.work.$dirty) {
        return error;
      }
      if (!this.$v.payload.work.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.work.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (this.validationErrors.work) {
        this.validationErrors.work.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  watch: {
    'user'(newVal) {
      if (newVal) {
        if (this.user) this.setUserInfo()
      }
    }
  },
  mounted() {

  },
  created() {
    if (this.user) this.setUserInfo()
    if (this.isProfessional) {

      this.$v.payload.work = {
        required,
        maxLength: maxLength(500),
      }
    }

  },
  methods: {
    ...mapActions({
      updateUserData: 'profile/UPDATE_USER_DATA',
      fetchUser: 'profile/FETCH_USER_DATA',
      login: 'auth/GET_TOKEN'
    }),
    resetForm() {
      this.isEdit = false
      this.$v.$reset()
      this.setUserInfo()
    },
    setUserInfo() {
      this.payload.secondName = this.user.secondName
      this.payload.name = this.user.name
      this.payload.lastName = this.user.lastName
      this.payload.country = this.user.country
      this.payload.city = this.user.city
      if (this.user.work) this.payload.work = this.user.work

    },
    update() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let obj = {}
        obj.name = this.payload.name
        obj.second_name = this.payload.secondName
        obj.last_name = this.payload.lastName
        obj.country = this.payload.country
        obj.city = this.payload.city
        if (this.isProfessional) obj.work = this.payload.work
        this.updateUserData(obj).then(() => {
          this.$toasted.success(this.$t('successResetData'));
          this.fetchUser()
          this.isEdit = false
        })
      }
    }
  }
}
